//
//
//
//

//&:china
export default {
  async created() {
    //window.sessionStorage.setItem("promocode:show", true);
    if (this.$route.query.code) {
      window.sessionStorage.setItem("promocode:show", true);
      window.sessionStorage.setItem("promocode:code", this.$route.query.code);
    } else {
      this.$router.push({
        name: "dashboard"
      });
    }
    await this.$store.dispatch("user/getUser");

    //china | percent ?
    try {
      const code = this.$route.query.code;
      //console.log('Promo.26: code=', code)
      const r = await this.$store.dispatch("billing/isChinaCode", {
        code
      });
      //console.log('Promo.28: r=', r)

      window.sessionStorage.setItem("promocode.type", r.type);
      window.sessionStorage.setItem("promocode.reason", r.reason);
    } catch (e) {
      var _e$response;
      if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 404) {
        window.sessionStorage.setItem("promocode.reason", 'notFound');
        window.sessionStorage.setItem("promocode.class", 'error');
        //console.log('Promo.35: r=404: !found')
      }
    }

    if (this.$store.state.user.user) {
      this.$router.push({
        name: "ProfilePlan"
      });
    } else {
      this.$router.push({
        name: "Register",
        params: {
          forceBackRoute: {
            name: "ProfilePlan"
          }
        }
      });
    }
  }
};